import {CommonModel} from "@/model/CommonModel";
import {getPageList, getList, getOne} from "@/api/CourseApi";
import {getOneByPath} from "../api/CourseApi";

class CourseModel {
    // 获取一个
    static async getOne(id) {
        let [res] = await getOne({
            courseId: id
        })
        if (res.code === "000000") {
            return res.data
        } else {
            return false
        }
    }

    // 获取一个通过path
    static async getOneByPath(path) {
        let [res] = await getOneByPath({
            coursePath: path
        })
        if (res.code === "000000") {
            return res.data
        } else {
            return false
        }
    }

    // 获取分页列表
    static async getPageList(page, size, sort, document) {
        let [data] = await getPageList(page, size, sort, document);
        return CommonModel.generateListMongo(data.data);
    }

    // 获取不分页列表
    static async getList(document) {
        let [data] = await getList(document);
        return data.data;
    }
}

export {CourseModel}
