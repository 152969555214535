import {request_async} from "@/utils/requestAsync";
import {API_URL} from "@/config/main";

// 获取一个
export async function getOne(data) {
  return request_async(API_URL + "/v1/course/infoByFront", "get", data);
}

// 获取一个-通过path
export async function getOneByPath(data) {
  return request_async(API_URL + "/v1/course/infoByFrontWithPath", "get", data);
}

// 获取列表-分页
export async function getPageList(page, size, sort, data) {
  return request_async(API_URL + `/v1/course/pageList?page=${page}&size=${size}&sort=${sort}`, "post_body", data);
}

// 获取列表-不分页
export async function getList(data) {
  return request_async(API_URL + `/v1/course/list`, "post_body", data);
}
