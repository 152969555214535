<template>
  <div class="page-course">
    <!--课程列表-->
    <div class="curriculum-list content-container">
      <div class="experiment-list flex flex-start flex-wrap">
        <el-card class="li" :body-style="{padding:'0px'}" v-for="item in course.list">
          <div class="bg-box" @click="clickOneCourse(item)">
            <img class="bg"
                 :src="item.avatarUrl"
                 alt="">
          </div>
          <div class="info-box"  @click="clickOneCourse(item)">
            <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
              <div class="name">{{ item.name }}</div>
            </el-tooltip>
            <div class="number-box flex flex-between">
              <div>
                <i class="el-icon-user"></i>
                <span>{{ item.userNumber }}</span>
              </div>
              <!--              <div>-->
              <!--                <i class="el-icon-sunny"></i>-->
              <!--                <span>0</span>-->
              <!--              </div>-->
              <div>
                <i class="el-icon-chat-line-round"></i>
                <span>{{ item.commentNumber }}</span>
              </div>
            </div>
            <div class="footer-box flex flex-between">
              <span class="type">{{ item.courseType }}</span>
            </div>
          </div>

        </el-card>
      </div>
      <el-empty description="暂无数据" v-if="course.list.length===0"></el-empty>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {CommonModel} from "../model/CommonModel";
import {CourseModel} from "../model/CourseModel";

export default {
  name: "CourseList",
  computed: {
    ...mapState({
      webConfig: state => state.webConfig,
    })
  },
  data() {
    return {
      courseSubject: {
        list: [],
        object: [],
        activeId: ""
      },
      course: {
        list: []
      }
    }
  },
  async mounted() {
    // 默认获取全部首页推荐课程
    this.clickOneCourseSubject()
  },
  methods: {
    // 点击某个学科的首页推荐课程列表
    async clickOneCourseSubject() {
      // 获取对应的课程列表
      let courseList = await CourseModel.getList({
        opened: true,
      })
      this.$set(this.course, "list", courseList)
    },
    // 点击某个课程
    clickOneCourse(item) {
      this.$router.push({
        name: "CourseInfo",
        params: {
          "coursePath": item.coursePath
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
@import '../style/app.less';

.page-course {
  margin-top: 20px;
}

.curriculum-list {
  margin-bottom: 30px;

  .first-filter-box {
  }

  .filter-box {
    font-size: 15px;

    .title {
      color: #222;
      margin-left: 20px;
    }

    .li {
      cursor: pointer;
      padding: 10px 20px;
      color: #555;
      font-size: 16px;

      &.active, &:hover {
        color: @main-color;
        font-weight: bold;
      }
    }
  }

  .experiment-list {
    margin-top: 10px;

    .li {
      margin-bottom: 20px;
      margin-right: 8px;
      cursor: pointer;

      &:hover {
        .bg-box {
          img.bg {
            transition: all 0.5s linear;
            transform: scale(1.1);
          }
        }
      }

      .bg-box {
        z-index: 1;
        width: 230px;
        height: 140px;
        overflow: hidden;

        img.bg {
          width: 230px;
          height: 140px;
          display: block;
          transition: all 0.5s linear;
          transform: scale(1);
        }
      }

      .info-box {
        padding: 10px;
        font-size: 14px;

        .name {
          color: #333;
          margin-bottom: 10px;
          max-width: 200px;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

        .number-box {
          margin-bottom: 10px;
          color: #888;

          i {
            margin-right: 5px;
          }
        }

        .footer-box {
          font-size: 13px;
          color: #999;
          padding: 10px 0px 0px 0px;
          border-top: 1px solid #cecece;
        }
      }

    }
  }
}
</style>
